<template>
    <AprFeaturesSectionWrapper v-if="blok" :blok="blok" class="book-section">
        <template #items>
            <div class="book-section__items">
                <NumberedFeaturesList v-if="withNumbers" :blok="blok" type="BookWithUs" />
                <template v-else>
                    <FeatureItem v-for="item in blok.Items" :key="item._uid" :blok="item" />
                </template>
            </div>
        </template>
        <template #cta>
            <div v-if="blok.ButtonText" class="book-section__btn">
                <UiAdvancedBtn
                    v-if="blok.ButtonAction === 'scrollToTop'"
                    component-variant="primary"
                    component-type="button"
                    :with-arrow="true"
                    @click="scrollToTop"
                >
                    {{ blok.ButtonText }}
                </UiAdvancedBtn>
                <UiAdvancedBtn
                    v-else-if="blok.ButtonLink.url || blok.ButtonLink.cached_url"
                    component-variant="primary"
                    component-type="link"
                    :with-arrow="true"
                    :to="getInternalLink(blok.ButtonLink)"
                    :target="blok.ButtonLink.target"
                >
                    {{ blok.ButtonText }}
                </UiAdvancedBtn>
            </div>
        </template>
    </AprFeaturesSectionWrapper>
</template>

<script setup lang="ts">
import AprFeaturesSectionWrapper from './AprFeaturesSectionWrapper.vue';
import FeatureItem from '../cards/FeatureItem.vue';
import type { IBookWithUs } from '~/types/BookWithUs';

const { blok } = defineProps<{ blok: IBookWithUs }>();

const withNumbers = computed(() => blok.Items.every((item) => !item.Image?.filename));

const scrollToTop = () => {
    if (typeof window !== 'undefined') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
};
</script>

<style lang="postcss" scoped>
.book-section {
    &__items {
        @apply grid md:grid-cols-3 gap-4 lg:gap-6 overflow-hidden;
        &:has(.apr-feature-item--row) {
            @apply lg:gap-16;
        }
    }
    &__btn {
        @apply mt-6 lg:mt-8;
        & > * {
            @apply w-full sm:w-auto;
        }
    }
}
</style>
